html {
    scroll-behavior: smooth;
}

body {
background-color: #000A13;
    background-image: url("dashboard.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    font-family:"Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif,"Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

a {
    text-decoration: none;
    color: black;
}

a:hover {
    text-decoration: underline;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

li {
    list-style: none;
}

body::-webkit-scrollbar {
    display: none;
}
.dashboard {
    display: flex;
    margin-left: 4.1rem;
    margin-right: 4.1rem;
    margin-top: 2.5rem;
}


.dashboard-grid {
    display: grid;
    grid-template-columns: repeat(4, minmax(0fr, 1fr));
    grid-auto-columns: 1fr;
    grid-template-rows: auto;
    margin: auto;
    justify-items: center;
    box-sizing: content-box;
    justify-content: space-evenly;
    place-items: center;
    grid-gap: 1rem;
    margin: 0rem;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
}

.top {
    position: relative;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 100%;
    width: 100%;
    border-radius: 35px;
    box-sizing: border-box;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    z-index: 2;
    overflow: hidden;
    align-content: center;
}
.widget {
    position: relative;
    /* background: white;
    background: linear-gradient(
        151.02deg,
        rgba(255, 255, 255, 0.5) 6.95%,
        rgba(255, 255, 255, 0.2) 91.05%
    );
    box-shadow: 1px 5px 40px 1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
    box-shadow: 1px 5px 40px 1px #00000033; */
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 100%;
    width: 100%;
    border-radius: 35px;
    box-sizing: border-box;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    z-index: 2;
    overflow: hidden;
    align-content: center;
    aspect-ratio: 1 / 1;
    min-width: 100%;
}



.jira {
    aspect-ratio: 2 / 1;
    grid-column: 3 / 5;    
    overflow: scroll;
    overscroll-behavior: contain;

}
.jira::-webkit-scrollbar {
    display: none;
}

.jiraboard {
    display: flex;
    border-radius: 10px;
    flex-direction: row;
    /* background-color: #eaeaea27;  */
    padding: 10px;
}

.jira__logo {
    position: absolute;
    background-image: url("Jira-Logo.png");
    background-size: cover;
    right: 20px;
    top: -30px;
    height: 80px;
    width: 180px;
 }

.jira__cards {
    border-radius: 17px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    width: 100%;
    padding: 0.57rem;
    padding-top: 0.2rem;
    padding-bottom: 0.5rem;
    margin: 0.12rem;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25); 
    transition: transform 100ms ease-out;
    font-weight: 400;
}

.jira__cards:hover {
    transform: scale(1.015);
}

.jira__column {
    border-radius: 20px;
    width: 100%;
}

.jira__avatar img {
    border-radius: 50%;
    float: right;
    user-select: none;
}

.taskName {
    font-weight: 500;
    font-size: 15px;
}

.epic{
    display: inline-block;
    background-color: #1460CC;
    width: fit-content;
    padding: 1px;
    padding-left: 3px;
    padding-right: 3px;
    border-radius: 4px;
    color: white;
    margin-top: 1px;
    font-weight: 500;
}

.jira__priority {
    float: right;
    margin-right: 0.8rem;
    height: 20px;
    line-height: 3rem;
    align-items: center;
}

.jira__lowest-priority {
    color: #2684ff;
}
.jira__low-priority {
    color: #0065ff;
}

.jira__medium-priority {
    color: #ffca65;
}

.jira__high-priority {
    color: #ff8f73;
}

.jira__highest-priority {
    color: #ff7452;
}

.sprint-name {
    display: inline-block;
    font-size: 10px;
    color: #000000ce;
}

.jira__sprint {
    position: absolute;
    font-size: 24px;
    padding-left: 0.5rem;
    font-weight: 600;

}

.rdt_TableHeader {
    font-size: 20px !important;
    background-color: red;
    min-height: 40px !important;
    padding-bottom: 0px !important;
    padding-left: 8px !important;
    margin-top: 1.5rem;

}

.top {
    display: flex;
    border-radius: 21px;
    margin-top: 15px;
    margin-bottom: 0px;
    width: 50%;
    height: 50px;
    text-align: center;
    justify-content: center;
    align-items: center;
    grid-row: 1 / -1;
}

.card {
    width: 90%;
    height: 65%;
    margin: auto auto;
    margin-top: 1%;
    background: linear-gradient(
        117.88deg,
        rgba(234, 234, 234, 0.9) 19.51%,
        rgba(234, 234, 234, 0.6) 76.28%
    );
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 3;
}

.card::-webkit-scrollbar {
    display: none;
}

.refreshButton {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    width: 90%;
    display: flex;
    justify-content: center;
    margin: auto auto;
    font-weight: 600;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    cursor: pointer;
    margin-top: 0.2rem;
    padding: 1%;
}

/* .refreshButton:hover{
    background-color: rgba(255, 255, 255, 0.6);

} */

.status__symbol {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-left: 0rem;
    margin-right: 1rem;
    align-self: center;
    justify-content: space-between;
}

@keyframes pulse-red {
	0% {
		transform: scale(0.90);
		box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
	}

	100% {
		transform: scale(0.90);
		box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
	}
}
@keyframes pulse-green {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(12, 211, 79, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(12, 211, 79, 0);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(12, 211, 79, 0);
	}
}

.uptime-status {
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 10%;
    margin: auto auto;
    margin-top: 5%;
    background: linear-gradient(
        117.88deg,
        rgba(234, 234, 234, 0.9) 19.51%,
        rgba(234, 234, 234, 0.6) 76.28%
    );
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    font-weight: 400;
}

.prof-it-pulse {
    display: flex;
    /* background-color: #80BCE7; */
    background-image: url("Prof-IT4All-Full Logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    align-items: center;
    margin-top: 5rem;
    margin-bottom: 4rem;
    border-radius: 5px;
    align-items: center;
    height: 125px;
    width: 250px;
    animation: pulse 5s ease-out infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1.05);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.05);
    }
}

.time {
    color: #eaeaea;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    margin: auto auto;
    text-align: center;
    /* text-shadow: 2px 4px 3px #00000040; */
    text-shadow: 2px 3px 7px #0000003b;
    margin-bottom: 6px;
}

.date {
    color: #eaeaea;
    text-align: center;
    font-size: 1.6rem;
    font-weight: bold;
    margin: auto auto;
    text-align: center;
    /* text-shadow: 2px 4px 3px #00000040; */
    text-shadow: 2px 3px 7px #0000003b;
    line-height: 1;
}


/* Weather */

.Weather {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.Weather__info {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
}

.Weather__icon {
    display: flex;
    position: relative;
    align-self: flex-end;
    width: 110px;
    height: 110px;
}

.weather__list {
    font-size: 1.05rem;
    /* margin-left: 0.75rem; */
    padding: 0;
    list-style: none;
}

.list__temperature {
    font-weight: 500;
    font-size: 3.5rem;
    line-height: 1;
    list-style: none;
    text-shadow: 2px 3px 7px #0000003b;
    text-align: center;
    margin-left: 1rem;
}

.list__temperature-symbol {
    font-weight: 700;
    font-size: 3.4rem;
}

.list__temp-minmax {
    font-size: 1.33rem;
    text-align: center;
    line-height: 1;

}

.list__info {
    font-weight: 500;
    list-style: none;
    text-shadow: 2px 3px 7px #0000003b;
    color: rgba(255, 255, 255, 0.87);
}

.list__card {
    background: rgba(255, 255, 255, 0.03);
    border-radius: 7px;
    box-shadow: 0 3px 17px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(15px);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    padding: 3px;
    margin-top: 6px;
}

.weather__other-icons {
    margin-right: 4px;
    margin-left: 2px;
    color: #c9f2ffcc
}
.weather__other-icons_sunset {
    font-size: 18px;
    margin-right: 7px;
    color: #fbde07;
}

.Weather__other-info {
    margin-top: 0rem;
}

.other-info__city {
    font-size: 1.2rem;
    margin-bottom: 1.93rem;
    margin-top: 0.5rem;
    text-align: left;
    width: 100%;
    height: 20%;
    text-shadow: 2px 3px 7px #0000003b;
}

.other-info__clouds {
    font-weight: 500;
    margin: 0;
    text-transform: capitalize;
    text-shadow: 2px 3px 7px #0000003b;
    text-align: center;
    color: rgba(255, 255, 255, 0.85);
    width: 125px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

}
.WeatherAndForecast {
    display: flex;
    flex-direction: column;
    color: #f2f2f2;
    padding: 0.5rem 1.5rem;
}

.WeatherAndForecast__container {
    display: flex;
    justify-content: space-between;
    padding-top: 0.3rem;
    padding-bottom: 0.8rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    /* margin-top: 0.55rem; */
    margin-top: calc(100% - 22.26rem);
    background: rgba(255, 255, 255, 0.03);
    border-radius: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.Forecast__title {
    font-size: 1rem;
    text-align: center;
    color: rgba(225, 225, 225, 0.9);
    margin-top: 0.3rem;
    margin-bottom: 0rem;
}

.Forecast__weather-icon {
    width: 60px;
    height: 60px;
}

.Forecast__temperature {
    display: flex;
    justify-content: center;
}

.temperature__max {
    margin-right: 0.25rem;
    color: #ffffff;
    font-weight: 500;
}

.temperature__min {
    margin-left: 0.3rem;
    color: #ffffff81;
    font-weight: 500;
}

.forecast-temperature__symbol {
    margin-left: 1px;
    font-size: 1rem;
}

.temperature__symbol {
    margin-left: 1px;
    font-size: 1.4rem;
}

.Loader {
    margin: 8rem auto;
    border: 5px solid rgba(225, 225, 225, 0.8);
    border-top: 5px solid #158ef1;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s ease-out infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.cloud-container {
    margin: 7rem auto;
    position: relative;
    height: 135px;
    width: 100px;
    border-bottom: 1px solid white;
}

.cloud {
    position: relative;
    margin: 2rem auto;
    top: 50px;
    height: 30px;
    width: 100px;
    background-color: white;
    border-radius: 50px;
    animation: cloud 1.5s linear infinite;
}

@keyframes cloud {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.03);
    }

    100% {
        transform: scale(1);
    }
}

.cloud::before {
    content: '';
    position: absolute;
    height: 22px;
    width: 22px;
    top: -15px;
    right: 15px;
    background-color: white;
    border-radius: 50%;
    box-shadow: -29px -5px 0 11px white;
}

.cloud::after {
    content: '';
    position: absolute;
    top: -7px;
    left: -2px;
    height: 37px;
    width: 37px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
}

.rain {
    position: relative;
    display: flex;
    z-index: 1;
    padding: 10px;
    bottom: -10px;
}

.droplet {
    position: relative;
    width: 4px;
    height: 5px;
    background-color: #ffffff8a;
    margin: 0 1px;
    border-radius: 10px;
    transform-origin: bottom;
    animation: rain 2s linear infinite;
}

@keyframes rain {
    0% {
        transform: translateY(0) scale(1);
    }

    70% {
        transform: translateY(60px) scale(0.75);
    }

    100% {
        transform: translateY(60px) scale(0);
    }
}

.droplet:nth-child(1) {
    animation-duration: 0.99s;
}

.droplet:nth-child(2) {
    animation-duration: 1.25s;
}

.droplet:nth-child(3) {
    animation-duration: 1.02s;
}

.droplet:nth-child(4) {
    animation-duration: 1.14s;
}

.droplet:nth-child(5) {

    animation-duration: 1.16s;
}

.droplet:nth-child(6) {
    animation-duration: 0.69s;
}

.droplet:nth-child(7) {
    animation-duration: 0.9s;
}

.droplet:nth-child(8) {
    animation-duration: 1.21s;
}

.droplet:nth-child(9) {
    animation-duration: 1.12s;
}

.droplet:nth-child(10) {
    animation-duration: 0.9;
}

.droplet:nth-child(11) {
    animation-duration: 1.09s;
}

.droplet:nth-child(12) {
    animation-duration: 1.13s;
}

.droplet:nth-child(12) {
    animation-duration: 0.87s;
}

.droplet:nth-child(13) {
    animation-duration: 0.76s;
}

.droplet:nth-child(14) {
    animation-duration: 1.1s;
}

.droplet:nth-child(15) {
    animation-duration: 1.21s;
}

.droplet:nth-child(16) {
    animation-duration: 1.12s;
}

.droplet:nth-child(17) {
    animation-duration: 1.19s;
}

.droplet:nth-child(18) {
    animation-duration: 1.08s;
}

.droplet:nth-child(19) {
    animation-duration: 0.7s;
}
.calendar {
    overflow: scroll;
}

.calendar::-webkit-scrollbar {
    display: none;
}

.fc .fc-button-primary {
    background-color: rgba(23, 23, 23, 0) !important;
    color: black !important;
    font-weight: 500 !important;
    border-radius: 50px !important;
    padding: 5px !important;
    margin: 5px !important;
    border-color: transparent !important;
    text-transform: capitalize !important;
    line-height: 1 !important;
}

.fc .fc-button-active {
    background-color: #ffffff86 !important;
}

.fc .fc-header-toolbar {
    margin: 0 !important;
}
.Search {
    margin-left: 3rem;
    margin-right: auto;
}

.Search__input {
    background-color: rgba(225, 225, 225, 0.6);
    border-radius: 20px 0px 0px 20px;
    border: 1px solid rgba(225, 225, 225, 0.6);
}

.Search__button {
    border-radius: 0px 20px 20px 0px ;
    border: 1px solid rgba(225, 225, 225, 0.7);
    cursor: pointer;
}

input:focus {
outline: none;
}

/* Weather search button */

.toggleButton {
    width: 25px;
    height: 25px;
    display: flex;
    border-radius: 30px;
    margin-left: auto;
    margin-right: 4%;
    margin-top: 4%;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 0px solid black;
    cursor: pointer;

}

.toggleButton:hover {
    transform: scale(125%);
    transition: transform 0s ease-in;
}

.searchMenu {
    list-style: none;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 0;
    overflow: hidden;
    max-width: 290px;
    z-index: 4;
  }
  
  .searchMenu.showMenu {
    width: 100%;
  }
  
  .fa-search {
      color: rgba(0, 0, 0, 0.7)
  }

.countdowntimer{
    margin-top: 1%;
    margin-left: 85%;
}

.verkeer {
    overflow: hidden;
    font-size: 17px;
}

.verkeer::-webkit-scrollbar {
    display: none;
}

.verkeer-scroll {
    overflow: scroll;
    /* height: 87%; 87.3*/
    height: 100%;
    overscroll-behavior: contain;
    border-radius: 35px;
    mask-image: linear-gradient(to bottom, rgb(255, 255, 255) calc(100% - 13px), transparent 100%), 
    linear-gradient(to top, rgb(255, 255, 255) calc(100% - 13px), transparent 100%);
    -webkit-mask-composite: destination-in;
    mask-composite: exclude;
}
.verkeer-scroll::-webkit-scrollbar {
    display: none;
}

.verkeer__fileCount {
    display: inline-block;
    position: absolute;
    margin: 0.7rem;
    width: 90%;
    max-width: 100%;
    /* width: clamp(23.66rem, 23.66rem, 100%); */
    max-width: 100%;
    background: linear-gradient(
        117.88deg,
        rgba(234, 234, 234, 0.9) 19.51%,
        rgba(234, 234, 234, 0.6) 76.28%
        );
    justify-content: flex-start;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
    border-radius: 20px;
    backdrop-filter: blur(30px);
    padding: 10px;
    z-index: 4;
    /* mask-image: linear-gradient(to bottom, black calc(100% - 10px), transparent 100%); */
}

.space {
    height: 44px;
    margin: 0.7rem;
}

.verkeer__cards {
    margin: auto auto;
    margin: 0.7rem;
    background: linear-gradient(
        117.88deg,
        rgba(234, 234, 234, 0.9) 19.51%,
        rgba(234, 234, 234, 0.6) 76.28%
    );
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
    border-radius: 20px;
    padding: 10px;
    z-index: 3;
}

.verkeer__toggleMap {
    display: block;
    position: absolute;
    background-color: white;
    z-index: 99;
    right: 0.8rem;
    bottom: 0.8rem;
    border-radius: 30px;
    padding: 11px;
    border: none;
}

.verkeer__toggleMap:hover {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.164);
}
.roadNumber {
    display: flex;
    width: 40px;
    height: 25px;
    color: white;
    text-align: center;
    border-radius: 8px;
    justify-content: center;
    margin-right: 0rem;
}

.verkeer__title {
    display: flex;
    font-weight: 500;
    
}

.verkeer-description {
    display: inline-block;
    text-shadow: 0px 0px #00000079
}
.locationText {
    font-size: 16px;
    color: #000000e3;
}

.directionText {
    margin-left: 0.5rem;
    font-size: 18px;
}

.fileIcon {
    display: inline-block;
    color: #e02323;
    margin-left: 5px;
}
.werkzaamheden {
    display: inline-block;
    margin-left: 5px;
}

.vertraging {
    display: inline-block;
    background-color: #FFD323;
    color: black;
    border-radius: 6px;
    margin-top: 2px;
    line-height: 1.2;
    padding-left: 4px;
    padding-right: 4px;
    font-weight: 500;
    border: 1px solid #FFCD1C;
}

.verkeer-nodata {
    display: flex;
    background-color: white;
    border-radius: 50px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    justify-content: center;
    margin: auto auto;
    padding: 1.5rem;
    animation: pulsing 2s infinite;
    overflow: hidden;
}

    @keyframes pulsing {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(0.99);
        }
        100% {
            transform: scale(1);
        }
    }

.verkeer-box {
    height: 100%;
    border-radius: 35px;
}

.verkeer-box b {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #74c9f0;
    background-image: url("Prof-IT4All Logo.png");
    background-size: cover;
    position: absolute;
    animation: moveX 2s linear 0s infinite alternate, moveY 3.6s linear 0s infinite alternate;
    }

.traffic-map {
    position: fixed;
    top: -3.2rem;
}



.modal {
    position: fixed;
    left: 0%;
    top:0%;
    right:0%;
    bottom:0%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    z-index: 4;
}
.modal.show{
    opacity: 1;
    pointer-events: visible;
}

.modal-content{
    width: 500px;
    background-color: #fff;
    transform: translateY(-200px);
    transition: all 0.3s ease-in-out;
}

.modal.show .modal.content{
    transform: translateY(0);
}


.modal-header, .modal-footer {
    padding: 10px;
}

.modal-title{
    margin: 0;
}
.modal-body {
    padding: 10px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}
.modal-button2{
    align-items: center;
    justify-content: center;
    margin-left: 63%;
}
.modal-button3 {
    margin-left: 5px;
    margin-right: 5px;
}

.box{
    width: 100%;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    padding-right: 200px;                
  }
  
  .box b {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #999999;
    background-image: url("Prof-IT4All Logo.png");
    background-size: cover;
    position: absolute;
    -webkit-animation: moveX 2s linear 0s infinite alternate, moveY 3.4s linear 0s infinite alternate;
    -moz-animation: moveX 2s linear 0s infinite alternate, moveY 3.4s linear 0s infinite alternate;
    -o-animation: moveX 2s linear 0s infinite alternate, moveY 3.4s linear 0s infinite alternate;
    animation: moveX 2s linear 0s infinite alternate, moveY 3.4s linear 0s infinite alternate;
    overflow: hidden;
  }
  @keyframes moveX {
    from { left: 0; } to { left: 88%; }
  }
  
  @keyframes moveY {
    from { top: 0; } to { top: 88%; }
  }

.algemeenNieuws{
    overflow: scroll;
}
.algemeenNieuws-scroll {
    overflow: scroll;
    font-size: 13px;
    padding: 8px;
    display: grid;
    grid-template-columns: 50% 50%

}

.algemeenNieuws::-webkit-scrollbar {
    display: none;
}
.algemeenNieuws-scroll::-webkit-scrollbar {
    display: none;
}

.algemeenNieuws-kaartjes{  
    margin: 0.22rem;
    background: linear-gradient(
        117.88deg,
        rgba(234, 234, 234, 0.9) 19.51%,
        rgba(234, 234, 234, 0.6) 76.28%
    );
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
    border-radius: 20px;
    overflow: hidden;
    z-index: 3;
    max-height: 200px;
}

.afbeeldingalgemeennieuws {
    display: inline-block;
    position: relative;
    object-fit: cover;
    width: 100%;    
    height: 70%;
    max-height: 150px;
    min-height: 50px;
}

.algemeenNieuws-text {
    /* background-color: white; */
    padding: 6px;
    font-weight: 500;
}

.nodata-algemeenNieuws {
    display: flex;
    background-color: white;
    border-radius: 50px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    justify-content: center;
    margin: auto auto;
    padding: 1.5rem;
    animation: pulsing 2s infinite;
}