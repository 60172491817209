.audioPlayer {
    --primary: #000000;
    --secondary: #ffffff;
    align-items: center;
    display: flex;
    width: 700px;
  }
  
  
  .playPause {
    background: var(--primary);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 32px;
    color: var(--secondary);
    display: flex;
    justify-content: center;
    align-items:center;
    margin-left: 5px;
  }
  
  .play {
    position: relative;
    left: 0px;
  }
  
  .currentTime,
  .duration {
    font-family: monospace;
    font-size: 16px;
  }
  
  .currentTime {
    margin-left: 5px;
  }
  
  .progressBar {
    --bar-bg: #ffe3d4;
    --seek-before-width: 0;
    --seek-before-color: #ffc2a1;
    --knobby: #3452a5;
    --selectedKnobby: #26c9c3;
    appearance: none;
    background: var(--bar-bg);;
    border-radius: 10px;
    position: relative;
    width: 0%;
    height: 0;
    outline: none;
    margin-left: 4px;
  }
  
  /* progress bar - safari */
  .progressBar::-webkit-slider-runnable-track {
    background: var(--bar-bg);;
    border-radius: 10px;
    position: relative;
    width: 0%;
    height: 0;
    outline: none;
  }
  
  /* progress bar - firefox */
  .progressBar::-moz-range-track {
    background: var(--bar-bg);;
    border-radius: 10px;
    position: relative;
    width: 0%;
    height: 0;
    outline: none;
  }
  
  .progressBar::-moz-focus-outer {
    border: 0;
  }
  
  /* progress bar - chrome and safari */
  .progressBar::before {
    content: '';
    height: 0;
    width: var(--seek-before-width);
    background-color: var(--seek-before-color);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
  }
  
  /* progress bar - firefox */
  .progressBar::-moz-range-progress {
    background-color: var(--seek-before-color);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 0;
  }
  
  /* knobby - chrome and safari */
  .progressBar::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 0;
    width: 0;
    border-radius: 50%;
    border: none;
    background-color: var(--knobby);
    cursor: pointer;
    position: relative;
    margin: -2px 0 0 0;
    z-index: 3;
    box-sizing: border-box;
  }
  
  /* knobby while dragging - chrome and safari */
  .progressBar:active::-webkit-slider-thumb {
    transform: scale(1.2);
    background: var(--selectedKnobby);
  }
  
  /* knobby - firefox */
  .progressBar::-moz-range-thumb {
    height: 0;
    width: 0;
    border-radius: 50%;
    border: transparent;
    background-color: var(--knobby);
    cursor: pointer;
    position: relative;
    z-index: 3;
    box-sizing: border-box;
  }
  
  /* knobby while dragging - firefox */
  .progressBar:active::-moz-range-thumb {
    transform: scale(1.2);
    background: var(--selectedKnobby);
  }

  .dropdownmenu {
    background-color: gray;
    border: none;
    border-radius: 20px;
    width: 100%;
    height: 40px;
    font-size: 22px;
    color: black;
    display: flex;
    margin-left: 5px;
    margin-right: 5px;
    padding: 5px;
  }
  .dropdownitem { 
    width: 100%;
    height: 40px;
    font-size: 20px;
    border-radius: 20px;
    color: black;
    display: flex;
    justify-content: center;
    text-align: start;
    align-items:center;
  }
    
  .dropdownmenu select {
    padding-right: 20px;
    color: #26c9c3;
  }
